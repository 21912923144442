import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.6_@babel+core@7.24.6_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.77.8/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.6_@babel+core@7.24.6_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.77.8/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/icons/app-store.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/icons/facebook-filled.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/icons/instagram-filled.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/icons/linkdin-filled.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/play-store.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/logo.svg");
;
import(/* webpackMode: "eager", webpackExports: ["Header"] */ "/vercel/path0/src/components/web/header/Header.tsx");
